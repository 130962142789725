import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { ActionType, AppActionTypes, RootState } from "../store/types";
import { Analytics } from "./analytics";
import { makeSelectFilter } from "../store/selectors";

export const trackingMiddleware: Middleware = (
  api: MiddlewareAPI<any, RootState>
) => (next: Dispatch) => (action: AppActionTypes) => {
  const filter = makeSelectFilter()(api.getState());
  switch (action.type) {
    case ActionType.TOGGLE_FILTER_GENRE:
      if (!filter.genres?.includes(action.genre)) {
        Analytics.reportFilterGenre(action.genre);
      }
      break;
    case ActionType.SET_FILTER_GENRES:
      if (action.genres && action.genres.length > 0) {
        action.genres.forEach(value => Analytics.reportFilterGenre(value));
      }
      break;
    case ActionType.TOGGLE_FILTER_LABEL:
      if (!filter.labels?.includes(action.label)) {
        Analytics.reportFilterLabel(action.label);
      }
      break;
    case ActionType.SET_FILTER_LABELS:
      if (action.labels && action.labels.length > 0) {
        action.labels.forEach(value => Analytics.reportFilterLabel(value));
      }
      break;
    case ActionType.SET_FILTER_SEARCH_TERM:
      if (action.searchTerm) {
        Analytics.reportFilterSearchTerm(action.searchTerm);
      }
      break;
  }

  return next(action);
};
