import React, { FunctionComponent, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MetaTags from "react-meta-tags";
import { Filter, Release, RootState } from "../../store/types";
import { clearAllFiltersAction, loadReleasesAction } from "../../store/actions";
import {
  makeSelectFilter,
  makeSelectLoading,
  makeSelectReleases
} from "../../store/selectors";
import { FeedItem } from "./FeedItem";
import {
  Box,
  Button,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { FeedPagination } from "./FeedPagination";

type StateProps = {
  loading: boolean;
  releases?: Release[];
  filter: Filter;
};
type DispatchProps = {
  loadReleases: () => void;
  clearAllFilters: () => void;
};

type Props = StateProps & DispatchProps;

const FeedComponent: FunctionComponent<Props> = ({
  loading,
  loadReleases,
  releases,
  filter,
  clearAllFilters
}) => {
  useEffect(() => {
    if (filter.initialized) {
      loadReleases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <MetaTags>
        <title>PsyFeed - Psychedelic Music Library</title>
        <meta
          property="og:title"
          content="PsyFeed - Psychedelic Music Library"
        />
        <meta property="og:image" content="https://psyfeed.org/logo512.png" />
      </MetaTags>
      <Box mb={4}>
        <FeedPagination />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={smallScreen ? 1 : 5}
        sx={{
          // minHeight: "50vh"
          height: "100%"
        }}
      >
        {loading ? (
          [1, 2, 3, 4, 5, 6].map(value => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={value}>
              <Skeleton variant="rectangular" height={500} />
            </Grid>
          ))
        ) : releases && releases.length > 0 ? (
          releases.map(value => (
            <Grid item xs={12} sm={6} md={6} lg={4} key={value.id}>
              <FeedItem release={value} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{
                width: "100%",
                height: "100%"
              }}
            >
              <Grid item>
                <Box textAlign="center">
                  <Typography variant="h4">No albums found</Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={clearAllFilters}
                    >
                      Clear All Filters
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box my={4}>
        <FeedPagination />
      </Box>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  loading: makeSelectLoading(),
  releases: makeSelectReleases(),
  filter: makeSelectFilter()
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadReleases: () => {
    dispatch(loadReleasesAction());
  },
  clearAllFilters: () => {
    dispatch(clearAllFiltersAction());
  }
});

const Feed = connect(mapStateToProps, mapDispatchToProps)(FeedComponent);

export { Feed, FeedComponent };
