import React, { FunctionComponent } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

type Props = {};

const Footer: FunctionComponent<Props> = () => {
  const smallerScreen = useMediaQuery("(max-width:600px)");

  const psyFeedCopy = <>&copy; {new Date().getFullYear()} PsyFeed</>;

  return (
    <Grid container my={2} mt={10} component="footer" spacing={2}>
      <Grid item flexGrow={1}>
        {smallerScreen && psyFeedCopy}
      </Grid>
      {!smallerScreen && <Grid item>{psyFeedCopy}</Grid>}
      <Grid item>
        <Link to="/privacy-policy">Privacy policy</Link>
      </Grid>
      <Grid item>
        <Link to="/cookie-policy">Cookie policy</Link>
      </Grid>
    </Grid>
  );
};

export { Footer };
