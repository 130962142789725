export const scrollToTop = () => {
  if (window.scrollY > 300) {
    try {
      window.scroll({
        top: 0,
        left: 0
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }
};
