import React, { FunctionComponent, useEffect, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Filter, FilterOptions, RootState } from "../../store/types";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputBase,
  Popover,
  styled,
  Switch
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import {
  setFilterAction,
  setFilterGenreAction,
  setFilterLabelsAction,
  setFilterSearchTermAction,
  toggleFilterGenreAction,
  toggleFilterLabelAction
} from "../../store/actions";
import {
  makeSelectFilter,
  makeSelectFilterOptions
} from "../../store/selectors";
import {
  DelimitedArrayParam,
  NumberParam,
  StringParam,
  useQueryParams
} from "use-query-params";
import { FilterIcon } from "./FilterIcon";
import useDebounce from "../../utils/debounceHook";
import produce from "immer";
import { Analytics } from "../../utils/analytics";
import { useLocation, useHistory } from "react-router-dom";
import { AppRoutes } from "../../utils/routes";

export type FilterStateProps = {
  filter: Filter;
  filterOptions: FilterOptions;
};
export type FilterDispatchProps = {
  setFilter: (value: Filter) => void;
  toggleFilterGenre: (genre: string) => void;
  setFilterGenres: (genres: string[]) => void;
  toggleFilterLabel: (label: string) => void;
  setFilterLabels: (selectedLabels: string[]) => void;
  setFilterSearchTerm: (searchTerm: string) => void;
};

type Props = FilterStateProps & FilterDispatchProps;

const SearchGrid = styled(Grid)(({ theme }) => ({
  "&&": {
    [theme.breakpoints.up("sm")]: {
      padding: 0
    }
  }
}));

const SearchBar = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  borderRadius: 30,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  height: 40,
  [theme.breakpoints.up("sm")]: {
    maxWidth: 320
  },
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: 41,
    right: 24,
    maxWidth: 220
  }
}));

const SearchIcon = styled("div")(({ theme }) => ({
  // color: theme.palette.primary.main,
  padding: theme.spacing(0, 2),
  height: "100%",
  // position: "absolute",
  // pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
  padding: theme.spacing(1, 1, 1, 0),
  // vertical padding + font size from searchIcon
  // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  transition: theme.transitions.create("width"),
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "12ch"
  }
}));

const ToggleIcon = ({ active }: { active: boolean }) => {
  return <FilterIcon color={active ? "primary" : "inherit"} />;
  // if (open) {
  //   return <ExpandLess />;
  // } else {
  //   return <ExpandMore />;
  // }
};

function CloseClearButtons({
  onClose,
  onClear
}: {
  onClose: () => void;
  onClear: () => void;
}) {
  return (
    <Box textAlign={"center"}>
      <Button variant={"text"} color={"primary"} onClick={onClose}>
        Close
      </Button>
      <Button variant={"text"} color={"primary"} onClick={onClear}>
        Clear
      </Button>
    </Box>
  );
}

const FilterComponent: FunctionComponent<Props> = ({
  filter,
  filterOptions,
  setFilter,
  toggleFilterGenre,
  toggleFilterLabel,
  setFilterLabels,
  setFilterSearchTerm
}) => {
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    genres: DelimitedArrayParam,
    labels: DelimitedArrayParam,
    search: StringParam
  });

  const [searchTerm, setSearchTerm] = useState(filter.searchTerm);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const location = useLocation();
  const history = useHistory();

  const redirectToFeedPageIfNeeded = () => {
    if (location.pathname !== AppRoutes.FEED_ROUTE) {
      history.push(AppRoutes.FEED_ROUTE);
    }
  };

  useEffect(() => {
    setFilterSearchTerm(debouncedSearchTerm);
  }, [debouncedSearchTerm, setFilterSearchTerm]);

  useEffect(() => {
    if (filter.initialized) {
      setQuery(
        {
          offset: filter.offset ? filter.offset : undefined,
          genres:
            filter.genres && filter.genres.length > 0
              ? filter.genres
              : undefined,
          labels:
            filter.labels && filter.labels.length > 0
              ? filter.labels
              : undefined,
          search: filter.searchTerm ? filter.searchTerm : undefined
        },
        "replaceIn"
      );
      setSearchTerm(filter.searchTerm || "");
    } else {
      const searchTermInit = query.search || "";
      setFilter({
        initialized: true,
        includeSingles: true,
        offset: query.offset || 0,
        genres: (query.genres as string[]) || [],
        labels: (query.labels as string[]) || [],
        searchTerm: searchTermInit
      });
      setSearchTerm(searchTermInit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const [showLabels, setShowLabels] = useState(false);

  const handleClearLabels = () => {
    setFilterLabels([]);
    setShowLabels(false);
  };

  function toggleShowLabels() {
    setShowLabels(!showLabels);
  }

  return (
    <Grid container spacing={2} justifyContent={"center"}>
      <SearchGrid item xs={12}>
        <SearchBar id="searchBar">
          <SearchIcon>
            {searchTerm ? (
              <Close
                onClick={() => {
                  setFilterSearchTerm("");
                }}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Search />
            )}
          </SearchIcon>
          <SearchInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event.target.value);
              redirectToFeedPageIfNeeded();
            }}
          />
          <div
            onClick={toggleShowLabels}
            id={"filterButton"}
            style={{ padding: "10px", cursor: "pointer" }}
          >
            <ToggleIcon
              active={
                (filter.labels && filter.labels.length > 0) ||
                !filter.includeSingles
              }
            />
          </div>
        </SearchBar>
      </SearchGrid>
      <Grid item xs={12}>
        <Grid container spacing={1} justifyContent={"center"}>
          {filterOptions.genres.map(genre => {
            const isSelected = filter.genres?.includes(genre);
            const toggleFunction = () => {
              toggleFilterGenre(genre);
              redirectToFeedPageIfNeeded();
            };
            return (
              <Grid item key={genre}>
                <Chip
                  label={genre}
                  variant="outlined"
                  onClick={toggleFunction}
                  {...(isSelected && { onDelete: toggleFunction })}
                  color={isSelected ? "primary" : "default"}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {filter.labels && filter.labels.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent={"center"}>
            {filter.labels.map(label => {
              const toggleFunction = () => {
                toggleFilterLabel(label);
                redirectToFeedPageIfNeeded();
              };
              return (
                <Grid item key={label}>
                  <Chip
                    label={label}
                    onClick={toggleFunction}
                    onDelete={toggleFunction}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      <Popover
        open={showLabels}
        anchorEl={document.getElementById("searchBar")}
        onClose={toggleShowLabels}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center"
        }}
      >
        <Box maxWidth={600} minWidth={320} p={3}>
          <Box textAlign="center" mb={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={!filter.includeSingles}
                  onChange={(event, checked) => {
                    setFilter(
                      produce(filter, (draft: Filter) => {
                        draft.includeSingles = !checked;
                        redirectToFeedPageIfNeeded();
                        if (checked) {
                          Analytics.reportHideSingles();
                        }
                      })
                    );
                  }}
                  color="primary"
                />
              }
              label="Hide Singles"
            />
          </Box>
          <Box mb={2}>
            <CloseClearButtons
              onClose={toggleShowLabels}
              onClear={handleClearLabels}
            />
          </Box>
          <Box textAlign="center" mb={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Labels</FormLabel>
              <FormGroup>
                {filterOptions.labels.map(label => (
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        color={"primary"}
                        checked={filter.labels?.includes(label)}
                        onChange={() => {
                          toggleFilterLabel(label);
                          redirectToFeedPageIfNeeded();
                        }}
                        name={label}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
          <CloseClearButtons
            onClose={toggleShowLabels}
            onClear={handleClearLabels}
          />
        </Box>
      </Popover>
    </Grid>
  );
};

export const mapStateToFilterProps = createStructuredSelector<
  RootState,
  FilterStateProps
>({
  filter: makeSelectFilter(),
  filterOptions: makeSelectFilterOptions()
});

export const mapDispatchToFilterProps = (
  dispatch: Dispatch
): FilterDispatchProps => ({
  setFilter: filter => {
    dispatch(setFilterAction(filter));
  },
  toggleFilterGenre: genre => {
    dispatch(toggleFilterGenreAction(genre));
  },
  setFilterGenres: genres => {
    dispatch(setFilterGenreAction(genres));
  },
  toggleFilterLabel: label => {
    dispatch(toggleFilterLabelAction(label));
  },
  setFilterLabels: selectedLabels => {
    dispatch(setFilterLabelsAction(selectedLabels));
  },
  setFilterSearchTerm: searchTerm => {
    dispatch(setFilterSearchTermAction(searchTerm));
  }
});

const Filters = connect(
  mapStateToFilterProps,
  mapDispatchToFilterProps
)(FilterComponent);

export { Filters };
