import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Box, styled } from "@mui/material";
import logo from "./PsyFeed-logo.png";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { setFilterOffsetAction, loadReleasesAction } from "../../store/actions";

type StateProps = {};
type DispatchProps = {
  resetOffset: () => void;
};
type Props = StateProps & DispatchProps;

const HomeLink = styled(Link)``;

const HeaderComponent: FunctionComponent<Props> = ({ resetOffset }) => {
  return (
    <Box my={4} component={"header"} minHeight={55}>
      <Box mx={"auto"} width={210} position={"relative"}>
        <HomeLink
          to="/"
          sx={{
            color: "text.primary",
            textDecoration: "none",
            display: "block"
          }}
          onClick={() => {
            resetOffset();
          }}
        >
          <img
            src={logo}
            alt="PsyFeed logo"
            style={{
              width: 210,
              cursor: "pointer"
            }}
          />
        </HomeLink>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  resetOffset: () => {
    dispatch(setFilterOffsetAction(0));
    dispatch(loadReleasesAction());
  }
});

const Header = connect(null, mapDispatchToProps)(HeaderComponent);

export { Header };
