import React, { FunctionComponent, useEffect } from "react";

type Props = {};

const CookiePolicy: FunctionComponent<Props> = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        href="https://www.iubenda.com/privacy-policy/79992470/cookie-policy"
        className="iubenda-nostyle no-brand iubenda-embed iub-no-markup iub-body-embed"
        title="Cookie Policy"
      >
        Cookie Policy
      </a>
    </div>
  );
};

export { CookiePolicy };
