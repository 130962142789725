import React, { FunctionComponent } from "react";
import { AppleMusicButton } from "./applemusic/AppleMusicButton";
import { Release } from "../../store/types";
import { SpotifyButton } from "./spotify/SpotifyButton";
import { TidalButton } from "./tidal/TidalButton";
import { AmazonMusicButton } from "./amazon/AmazonMusicButton";
import { YouTubeButton } from "./youtube/YouTubeButton";
import { DeezerButton } from "./deezer/DeezerButton";
import { ShopButton } from "./shop/ShopButton";

type Props = {
  release: Release;
  extended?: boolean;
};

export const ExtendedButtonContext = React.createContext<boolean | undefined>(
  false
);

const StreamingButtons: FunctionComponent<Props> = ({ release, extended }) => {
  return (
    <ExtendedButtonContext.Provider value={extended}>
      <TidalButton release={release} />
      <AppleMusicButton release={release} />
      <SpotifyButton release={release} />
      <DeezerButton release={release} />
      <YouTubeButton release={release} />
      <ShopButton release={release} noMargin />
      {extended && (
        <>
          <AmazonMusicButton release={release} />
        </>
      )}
    </ExtendedButtonContext.Provider>
  );
};

export { StreamingButtons };
