import {
  ActionType,
  ClearAllFiltersAction,
  Filter,
  LoadFilterOptionsAction,
  LoadReleasesAction,
  SelectReleaseAction,
  SelectReleaseCleanupAction,
  SetFilterAction,
  SetFilterGenresAction,
  SetFilterLabelsAction,
  SetFilterOffsetAction,
  SetFilterSearchTermAction,
  ToggleFilterGenreAction,
  ToggleFilterLabelAction
} from "./types";

export const loadGenresAction = (): LoadFilterOptionsAction => ({
  type: ActionType.LOAD_FILTER_OPTIONS
});

export const loadReleasesAction = (): LoadReleasesAction => ({
  type: ActionType.LOAD_RELEASES
});

export const selectReleaseAction = (id: string): SelectReleaseAction => ({
  type: ActionType.SELECT_RELEASE,
  id
});

export const cleanupReleaseAction = (): SelectReleaseCleanupAction => ({
  type: ActionType.SELECT_RELEASE_CLEANUP
});

export const setFilterAction = (filter: Filter): SetFilterAction => ({
  type: ActionType.SET_FILTER,
  filter
});

export const toggleFilterGenreAction = (
  genre: string
): ToggleFilterGenreAction => ({
  type: ActionType.TOGGLE_FILTER_GENRE,
  genre
});

export const toggleFilterLabelAction = (
  label: string
): ToggleFilterLabelAction => ({
  type: ActionType.TOGGLE_FILTER_LABEL,
  label: label
});

export const setFilterGenreAction = (
  genres: string[]
): SetFilterGenresAction => ({
  type: ActionType.SET_FILTER_GENRES,
  genres
});

export const setFilterLabelsAction = (
  labels: string[]
): SetFilterLabelsAction => ({
  type: ActionType.SET_FILTER_LABELS,
  labels
});

export const setFilterOffsetAction = (
  offset: number
): SetFilterOffsetAction => ({
  type: ActionType.SET_FILTER_OFFSET,
  offset
});

export const setFilterSearchTermAction = (
  searchTerm: string
): SetFilterSearchTermAction => ({
  type: ActionType.SET_FILTER_SEARCH_TERM,
  searchTerm
});

export const clearAllFiltersAction = (): ClearAllFiltersAction => ({
  type: ActionType.CLEAR_ALL_FILTERS
});
