import React, { FunctionComponent, useEffect } from "react";

type Props = {};

const PrivacyPolicy: FunctionComponent<Props> = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://cdn.iubenda.com/iubenda.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        href="https://www.iubenda.com/privacy-policy/79992470"
        className="iubenda-nostyle no-brand iub-no-markup iubenda-embed iub-body-embed"
        title="Privacy Policy"
      >
        Privacy Policy
      </a>
    </div>
  );
};

export { PrivacyPolicy };
