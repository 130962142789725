import React, { FunctionComponent } from "react";
import icon from "./yt_icon_rgb.png";
import iconExtended from "./yt_logo_rgb_dark.png";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release };

const YouTubeButton: FunctionComponent<Props> = ({ release }) => {
  return (
    <StreamingButton
      link={release.streamingServices.youtube}
      release={release}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"youtube"}
    />
  );
};

export { YouTubeButton };
