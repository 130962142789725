import React, { FunctionComponent } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Filter, FilterOptions, RootState } from "../../store/types";
import { Pagination, Stack, useMediaQuery } from "@mui/material";
import {
  makeSelectFilter,
  makeSelectFilterOptions
} from "../../store/selectors";
import { setFilterOffsetAction } from "../../store/actions";
import { scrollToTop } from "../../utils/windowUtils";
import { Analytics } from "../../utils/analytics";

type StateProps = {
  filterOptions: FilterOptions;
  filter: Filter;
};
type DispatchProps = {
  setFilterOffset: (offset: number) => void;
};

type Props = StateProps & DispatchProps;

const FeedPaginationComponent: FunctionComponent<Props> = ({
  filterOptions,
  filter,
  setFilterOffset
}) => {
  const biggerScreen = useMediaQuery("(min-width:600px)");

  return (
    <Stack alignItems={"center"}>
      <Pagination
        shape="rounded"
        siblingCount={biggerScreen ? 3 : 0}
        color="primary"
        page={filter.offset / 21 + 1}
        count={Math.ceil(filterOptions.totalAlbums / 21)}
        onChange={(e, page) => {
          setFilterOffset(21 * (page - 1));
          scrollToTop();
          Analytics.reportFeedPageNavigation(page);
        }}
      />
    </Stack>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  filterOptions: makeSelectFilterOptions(),
  filter: makeSelectFilter()
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setFilterOffset: (offset: number) => {
    dispatch(setFilterOffsetAction(offset));
  }
});

const FeedPagination = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedPaginationComponent);

export { FeedPagination, FeedPaginationComponent };
