import React, { FunctionComponent } from "react";
import icon from "./EQ.svg";
import iconExtended from "./en_US-dark.png";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release };

const DeezerButton: FunctionComponent<Props> = ({ release }) => {
  return (
    <StreamingButton
      link={release?.streamingServices.deezer}
      release={release}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"deezer"}
    />
  );
};

export { DeezerButton };
