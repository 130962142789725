import React, { FunctionComponent } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ArtworkImage } from "../store/types";
import LazyLoad from "react-lazyload";

type Props = {
  artworkImage: ArtworkImage;
  album: string;
};

const ArtWork: FunctionComponent<Props> = ({ artworkImage, album }) => {
  const theme = useTheme();
  const largeDisplay = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <LazyLoad height={390} offset={300}>
      <img
        style={{
          width: "100%",
          height: "auto",
          display: "block",
          maxWidth: 600,
          maxHeight: 600
        }}
        src={largeDisplay ? artworkImage.imageL : artworkImage.imageM}
        alt={`${album} cover`}
      />
    </LazyLoad>
  );
};

export { ArtWork };
