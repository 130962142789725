import { call, fork, put, select, takeLatest } from "@redux-saga/core/effects";
import {
  ActionType,
  LoadFilterOptionsSuccessAction,
  LoadReleasesSuccessAction,
  SelectReleaseAction,
  SelectReleaseSuccessAction
} from "../store/types";
import { Api } from "../utils/apiClient";
import { makeSelectFilter } from "../store/selectors";

function* fetchFilterOptions() {
  try {
    const filterOptions = yield call(Api.fetchFilterOptions);
    const action: LoadFilterOptionsSuccessAction = {
      type: ActionType.LOAD_FILTER_OPTIONS_SUCCESS,
      filterOptions
    };

    yield put(action);
  } catch (e) {
    console.error("Genres fetch error", e);
  }
}

function* fetchData() {
  try {
    yield put({ type: ActionType.START_LOADADING });
    const filter = yield select(makeSelectFilter());
    const releases = yield call(Api.fetchData, filter);
    const action: LoadReleasesSuccessAction = {
      type: ActionType.LOAD_RELEASES_SUCCESS,
      releases
    };

    yield put(action);
  } catch (e) {
    console.error("Fetch Error", e);
  } finally {
    yield put({ type: ActionType.STOP_LOADADING });
  }
}

function* selectRelease(action: SelectReleaseAction) {
  try {
    yield put({ type: ActionType.START_LOADADING });
    const release = yield call(Api.fetchRelease, action.id);
    const responseAction: SelectReleaseSuccessAction = {
      type: ActionType.SELECT_RELEASE_SUCCESS,
      release
    };
    yield put(responseAction);
  } catch (e) {
    console.error("Fetch Error", e);
  } finally {
    yield put({ type: ActionType.STOP_LOADADING });
  }
}

function* fetchGenresSaga() {
  yield takeLatest(ActionType.LOAD_FILTER_OPTIONS, fetchFilterOptions);
}

function* fetchDataSaga() {
  yield takeLatest(ActionType.LOAD_RELEASES, fetchData);
}

function* selectReleaseSaga() {
  yield takeLatest(ActionType.SELECT_RELEASE, selectRelease);
}

export default function* rootSaga() {
  yield fork(fetchGenresSaga);
  yield fork(fetchDataSaga);
  yield fork(selectReleaseSaga);
}
