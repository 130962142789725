import { Location, LocationListener, UnregisterCallback } from "history";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Analytics } from "./analytics";

const sendPageView: LocationListener = (location: Location): void => {
  Analytics.sendPageView(location);
};

interface Props {
  children: JSX.Element;
}

Analytics.initialize();

const GAListener = ({ children }: Props): JSX.Element => {
  const history = useHistory();
  useEffect((): UnregisterCallback | void => {
    sendPageView(history.location, "REPLACE");
    return history.listen(sendPageView);
  }, [history]);

  return children;
};

export { GAListener };
