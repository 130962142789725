import { ActionType, AppActionTypes, AppState, Filter } from "./types";
import produce from "immer";
import { Reducer } from "react";

const initialFilterState: Filter = {
  initialized: false,
  genres: [],
  labels: [],
  offset: 0,
  includeSingles: true,
  searchTerm: ""
};
export const initialState: AppState = {
  loading: true,
  dataLoaded: false,
  releases: undefined,
  selectedRelease: undefined,
  filter: initialFilterState,
  filterOptions: {
    genres: [
      "Dark Psy",
      "Full-on",
      "Goa Trance",
      "Hi-Tech",
      "Minimal Psy",
      "Progressive",
      "Psychedelic",
      "Psychill",
      "Psytrance",
      "Suomi"
    ],
    labels: [],
    totalAlbums: 0
  }
};

const reducer: Reducer<AppState, AppActionTypes> = (
  state: AppState = initialState,
  action: AppActionTypes
): AppState => {
  return produce(state, (draft: AppState) => {
    switch (action.type) {
      case ActionType.START_LOADADING:
        draft.loading = true;
        break;
      case ActionType.STOP_LOADADING:
        draft.loading = false;
        break;
      case ActionType.LOAD_FILTER_OPTIONS_SUCCESS:
        draft.filterOptions = action.filterOptions;
        draft.filterOptions.genres.sort();
        draft.filterOptions.labels.sort();
        break;
      case ActionType.LOAD_RELEASES_SUCCESS:
        draft.dataLoaded = true;
        draft.releases = action.releases.albums;
        draft.filterOptions.totalAlbums = action.releases.count;
        break;
      case ActionType.SELECT_RELEASE_SUCCESS:
        draft.selectedRelease = action.release;
        break;
      case ActionType.SELECT_RELEASE_CLEANUP:
        draft.selectedRelease = undefined;
        break;
      case ActionType.SET_FILTER:
        draft.filter = action.filter;
        break;
      case ActionType.TOGGLE_FILTER_GENRE:
        if (draft.filter.genres?.includes(action.genre)) {
          draft.filter.genres = [];
        } else {
          draft.filter.genres = [action.genre];
        }
        draft.filter.offset = 0;
        break;
      case ActionType.TOGGLE_FILTER_LABEL:
        if (draft.filter.labels?.includes(action.label)) {
          draft.filter.labels = draft.filter.labels.filter(
            value => value !== action.label
          );
        } else {
          draft.filter.labels = [...(draft.filter.labels || []), action.label];
        }
        break;
      case ActionType.SET_FILTER_LABELS:
        draft.filter.labels = action.labels;
        draft.filter.offset = 0;
        break;
      case ActionType.SET_FILTER_GENRES:
        draft.filter.genres = action.genres;
        draft.filter.offset = 0;
        break;
      case ActionType.SET_FILTER_OFFSET:
        draft.filter.offset = action.offset;
        break;
      case ActionType.SET_FILTER_SEARCH_TERM:
        draft.filter.searchTerm = action.searchTerm;
        break;
      case ActionType.CLEAR_ALL_FILTERS:
        draft.filter = { ...initialFilterState, initialized: true };
        break;
    }
  });
};

export default reducer;
