import React, { Suspense } from "react";
import "./App.css";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from "connected-react-router";
import { QueryParamProvider } from "use-query-params";
import history from "./utils/history";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  NoSsr,
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider
} from "@mui/material";
import reducer from "./store/reducer";
import { dataSaga } from "./sagas";
import { Feed } from "./components/feed/Feed";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppRoutes } from "./utils/routes";
import { ReleaseDetails } from "./components/ReleaseDetails";
import { Startup } from "./components/Startup";
import { Loading } from "./components/Loading";
import { Header } from "./components/header/Header";
import { Footer } from "./components/Footer";
import { GAListener } from "./utils/GaListener";
import { PrivacyPolicy } from "./components/pages/PrivacyPolicy";
import { CookiePolicy } from "./components/pages/CookiePolicy";
import { initLogging } from "./utils/logging";
import { saveState } from "./utils/localStorage";
import { trackingMiddleware } from "./utils/trackingMiddleware";
import { Filters } from "./components/feed/Filters";

const fontFamilyHeaders = [
  '"Doppio One"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(",");

let theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#303030",
      paper: "#424242"
    },
    primary: {
      main: "#FF6E00"
    },
    success: {
      main: "#4caf50"
    },
    info: {
      main: "#2196f3"
    },
    warning: {
      main: "#FFC700"
    },
    error: {
      main: "#F44336"
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h1: {
      fontFamily: fontFamilyHeaders
    },
    h2: {
      fontFamily: fontFamilyHeaders
    },
    h3: {
      fontFamily: fontFamilyHeaders
    },
    h4: {
      fontFamily: fontFamilyHeaders
    },
    button: {
      fontFamily: fontFamilyHeaders
    }
  }
});

theme = responsiveFontSizes(theme);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    app: reducer
  }),
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history),
      trackingMiddleware
    )
  )
);

store.subscribe(() => {
  saveState(store.getState().app);
});

sagaMiddleware.run(dataSaga);

initLogging();

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <GAListener>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <NoSsr>
                  <Container maxWidth="lg" style={{ position: "relative" }}>
                    <Box
                      style={{
                        display: "flex",
                        minHeight: "100vh",
                        flexDirection: "column"
                      }}
                    >
                      <Header />
                      <Box
                        component={"main"}
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          width: "100%"
                        }}
                      >
                        <Startup>
                          <Suspense fallback={<Loading />}>
                            <Box mb={3}>
                              <Filters />
                            </Box>
                            <Switch>
                              <Route
                                exact
                                path={AppRoutes.FEED_ROUTE}
                                component={Feed}
                              />
                              <Route
                                path={`${AppRoutes.RELEASE_DETAILS_ROUTE}/:id`}
                                component={ReleaseDetails}
                              />
                              <Route
                                path={AppRoutes.PRIVACY}
                                component={PrivacyPolicy}
                              />
                              <Route
                                path={AppRoutes.COOKIE}
                                component={CookiePolicy}
                              />
                              <Redirect from={"*"} to={AppRoutes.FEED_ROUTE} />
                            </Switch>
                          </Suspense>
                        </Startup>
                      </Box>
                      <Footer />
                    </Box>
                  </Container>
                </NoSsr>
              </ThemeProvider>
            </StyledEngineProvider>
          </GAListener>
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
