import Rollbar from "rollbar";

let rollbar: Rollbar;

export const initLogging = () => {
  rollbar = new Rollbar({
    accessToken: "0b1b4303fc5d49eabc2b55a6a97af6fc",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: process.env.NODE_ENV === "production",
    payload: {
      environment: process.env.NODE_ENV
    }
  });
};

export const logError = (e: any) => {
  if (rollbar) {
    rollbar.error(e);
  }
};
