import React, { FunctionComponent } from "react";
import { Release } from "../../store/types";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { formatDateShort } from "../../utils/date";
import { StreamingButtons } from "../streaming/StreamingButtons";
import { ArtWork } from "../ArtWork";
import { connect } from "react-redux";
import {
  FilterDispatchProps,
  FilterStateProps,
  mapDispatchToFilterProps,
  mapStateToFilterProps
} from "./Filters";
import { scrollToTop } from "../../utils/windowUtils";
import { ReleaseLink } from "./ReleaseLink";

type InputProps = {
  release: Release;
};

type Props = InputProps & FilterStateProps & FilterDispatchProps;

const FeedItemComponent: FunctionComponent<Props> = ({
  release,
  filter,
  setFilterGenres,
  setFilterLabels
}) => {
  const isLabelSelected = () => filter.labels?.includes(release.label);
  const isGenreSelected = () => filter.genres?.includes(release.genre);

  return (
    <Paper
      sx={{
        height: "100%",
        margin: "0 auto"
      }}
    >
      <Grid
        container
        direction={"column"}
        sx={{
          height: "100%",
          margin: "0 auto"
        }}
      >
        <Grid
          item
          style={{
            overflow: "hidden"
          }}
        >
          <ReleaseLink release={release}>
            <ArtWork
              artworkImage={release.artworkImage}
              album={release.album}
            />
          </ReleaseLink>
        </Grid>
        <Grid
          item
          sx={{
            // display: "flex",
            // flexDirection: "column",
            flexGrow: 1,
            padding: "12px 20px 10px",
            overflow: "hidden"
            // minHeight: 245
          }}
        >
          <Grid container sx={{ height: "100%" }} direction={"column"}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container style={{ marginTop: -5 }}>
                    <Grid item xs={6}>
                      <Box
                        fontSize="0.7rem"
                        aria-label={"single"}
                        component="span"
                      >
                        {release.single && "Single"}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        textAlign: "right"
                      }}
                    >
                      <Tooltip title="Release Date" arrow>
                        <Box
                          fontSize="0.7rem"
                          // mt="6px"
                          component="span"
                          aria-label={"release date"}
                        >
                          {formatDateShort(release.releaseDate)}
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box fontSize={18}>{release.artist}</Box>
                </Grid>
                <Grid item xs={12}>
                  <Box fontSize={22}>
                    <ReleaseLink release={release}>{release.album}</ReleaseLink>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Box my={1}>
                <Divider
                  sx={{
                    marginLeft: "36px",
                    marginRight: "36px"
                  }}
                />
              </Box>
            </Grid>
            <Grid item style={{ height: 30 }}>
              <Grid container>
                <Grid item xs={7}>
                  <Box mb={1}>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textDecoration: "none",
                        color: "text.primary",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        if (!isLabelSelected()) {
                          setFilterLabels([release.label]);
                          setFilterGenres([]);
                          scrollToTop();
                        }
                      }}
                    >
                      {release.label}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5} sx={{ textAlign: "right" }}>
                  <Chip
                    label={release.genre}
                    variant="outlined"
                    size="small"
                    clickable={!isGenreSelected()}
                    color={isGenreSelected() ? "primary" : "default"}
                    onClick={() => {
                      if (!isGenreSelected()) {
                        setFilterGenres([release.genre]);
                        setFilterLabels([]);
                        scrollToTop();
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Box mt={2} mb={2} textAlign="center">
            <StreamingButtons release={release} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const FeedItem = connect(
  mapStateToFilterProps,
  mapDispatchToFilterProps
)(FeedItemComponent);

export { FeedItem };
