import React, { FunctionComponent, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Release, RootState } from "../store/types";
import { RouteComponentProps } from "react-router-dom";
import { makeSelectSelectedRelease } from "../store/selectors";
import { cleanupReleaseAction, selectReleaseAction } from "../store/actions";
import { StreamingButtons } from "./streaming/StreamingButtons";
import { Box, Grid, Link, styled } from "@mui/material";
import { ArtWork } from "./ArtWork";
import { formatDate } from "../utils/date";
import MetaTags from "react-meta-tags";
import { Analytics } from "../utils/analytics";
import { SimilarReleases } from "./SimilarReleases";

type StateProps = { release?: Release };
type DispatchProps = {
  loadRelease: (id: string) => void;
  cleanupRelease: () => void;
};
type RouteProps = { id: string };

type Props = StateProps & DispatchProps & RouteComponentProps<RouteProps>;

const ArtworkContainer = styled(Box)(({ theme }) => ({
  marginLeft: "auto",
  maxWidth: 600,
  [theme.breakpoints.between("xs", "md")]: {
    marginRight: "auto"
  }
}));

const ReleaseDataContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between("xs", "md")]: {
    maxWidth: 600,
    marginRight: "auto",
    marginLeft: "auto"
  }
}));

const TitleBox: FunctionComponent = ({ children }) => {
  return (
    <Box
      component="span"
      color="primary.main"
      fontSize={"subtitle1.fontSize"}
      fontFamily={"Doppio One"}
    >
      {children}
    </Box>
  );
};

const AlbumInfoRow: FunctionComponent<{
  title: string;
}> = ({ title, children }) => {
  return (
    <Grid container>
      <Grid item sx={{ width: 120 }}>
        <TitleBox>{title}</TitleBox>
      </Grid>
      <Grid item xs={7}>
        {children}
      </Grid>
    </Grid>
  );
};

const ReleaseDetailsComponent: FunctionComponent<Props> = ({
  match: {
    params: { id }
  },
  loadRelease,
  cleanupRelease,
  release
}) => {
  useEffect(() => {
    if (!release || release.id !== id) {
      loadRelease(id);
    }
    return () => {
      cleanupRelease();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function getAlbumName() {
    if (!release) {
      return "";
    }

    return release.album + (release.single ? " [Single]" : "");
  }

  if (!release || release.id !== id) {
    return null;
  }

  return (
    <Grid container spacing={4} justifyContent={"center"}>
      {release && (
        <>
          <MetaTags>
            <title>PsyFeed - {`${release.artist} - ${release.album}`}</title>
            <meta
              property="og:title"
              content={`${release.album} by ${release.artist}`}
            />
            <meta property="og:image" content={release.artworkImage.imageM} />
          </MetaTags>
          <Grid item xs={12} md={7}>
            <ArtworkContainer>
              <ArtWork
                artworkImage={release.artworkImage}
                album={release.album}
              />
            </ArtworkContainer>
          </Grid>
          <Grid item xs={12} md={5}>
            <ReleaseDataContainer>
              <Box
                sx={{
                  fontFamily: "Doppio One",
                  fontWeight: "400",
                  fontSize: "48px",
                  lineHeight: "56px",
                  color: "#FF6E00",
                  mb: 2
                }}
              >
                Album Details
              </Box>
              <Grid container justifyContent={"center"}>
                <AlbumInfoRow title="Interpreter">
                  {release.artist}
                </AlbumInfoRow>
                <AlbumInfoRow title="Album">{getAlbumName()}</AlbumInfoRow>
                <AlbumInfoRow title="Genre">{release.genre}</AlbumInfoRow>
                <AlbumInfoRow title="Label">{release.label}</AlbumInfoRow>
                <AlbumInfoRow title="Release Date">
                  {formatDate(release.releaseDate)}
                </AlbumInfoRow>
                {release.buy && release.buy.link && (
                  <AlbumInfoRow title="Buy">
                    <Link
                      href={release.buy.link}
                      target="_blank"
                      rel="noopener"
                      sx={{
                        textDecoration: "underline",
                        color: "#fff"
                      }}
                      onClick={() => {
                        Analytics.reportShopClick(
                          release.buy.title,
                          release.id
                        );
                      }}
                    >
                      {release.buy.title}
                    </Link>
                  </AlbumInfoRow>
                )}
              </Grid>
              {release.streamingServices && (
                <Box mt={4}>
                  <StreamingButtons release={release} />
                </Box>
              )}
            </ReleaseDataContainer>
          </Grid>
          <Grid item xs={12}>
            <SimilarReleases similarTo={release} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  release: makeSelectSelectedRelease()
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadRelease: (id: string) => {
    dispatch(selectReleaseAction(id));
  },
  cleanupRelease: () => {
    dispatch(cleanupReleaseAction());
  }
});

const ReleaseDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseDetailsComponent);

export { ReleaseDetails, ReleaseDetailsComponent };
