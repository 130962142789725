import axios from "axios";
import {
  FetchReleasesResponse,
  Filter,
  FilterOptions,
  Release
} from "../store/types";

const axiosClient = axios;

const Api = {
  fetchData: (filter: Filter): Promise<FetchReleasesResponse> => {
    let params;
    if (filter.searchTerm && filter.searchTerm.length > 0) {
      params = {
        searchTerm: filter.searchTerm,
        includeSingles: true
      };
    } else {
      params = {
        offset: filter.offset || 0,
        includeSingles: filter.includeSingles || true,
        ...(filter.genres && { genres: filter.genres }),
        ...(filter.labels && { labels: filter.labels })
      };
    }
    return axiosClient
      .get(`/api/albums`, {
        params: params
      })
      .then(response => {
        return response.data;
      });
  },
  fetchRelease: (id: string): Promise<Release> => {
    return axiosClient.get(`/api/albums/${id}`).then(response => {
      return response.data;
    });
  },
  fetchFilterOptions: (): Promise<FilterOptions> => {
    return axiosClient.get(`/api/filter-options`).then(response => {
      return response.data;
    });
  }
};

export { Api };
