import React, { FunctionComponent } from "react";
import icon from "./Amazonmusiclogo.png";
import iconExtended from "./amazon-Music-Badge.png";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release };

const AmazonMusicButton: FunctionComponent<Props> = ({ release }) => {
  return (
    <StreamingButton
      link={release.streamingServices.amazonMusic}
      release={release}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"googlePlay"}
    />
  );
};

export { AmazonMusicButton };
