import * as ls from "local-storage";
import { logError } from "./logging";
import { AppState } from "../store/types";

// @ts-ignore
ls.backend(localStorage);

export const clearAll = () => {
  // @ts-ignore
  ls.clear();
};

export const loadAppState = () => {
  try {
    const appState: any = ls.get<any>("appState");
    if (
      appState === null ||
      appState === undefined ||
      typeof appState !== "object"
    ) {
      clearAll();
      return undefined;
    }

    return appState;
  } catch (e) {
    logError(e);
    clearAll();
    return undefined;
  }
};

export const saveState = (appState: AppState) => {
  ls.set<any>("appState", {
    // loggedIn: appState.loggedIn
  });
};
