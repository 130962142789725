import React, { FC, useEffect, useState } from "react";
import { Release } from "../store/types";
import { Box, Divider, Grid, Theme, useMediaQuery } from "@mui/material";
import { Api } from "../utils/apiClient";
import { ArtWork } from "./ArtWork";
import { ReleaseLink } from "./feed/ReleaseLink";
import { Analytics } from "../utils/analytics";

type Props = {
  similarTo: Release;
};
export const SimilarReleases: FC<Props> = ({ similarTo }) => {
  const [similarReleases, setSimilarReleases] = useState<Release[]>([]);
  const smallDisplay = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  useEffect(() => {
    Api.fetchData({
      genres: [similarTo.genre]
    }).then(data => {
      setSimilarReleases(
        data.albums.filter(release => release.id !== similarTo.id)
      );
    });
  }, [similarTo]);

  if (similarReleases.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Divider
          sx={{
            width: "70%",
            mt: 5,
            mx: "auto",
            color: "rgba(255, 255, 255, 0.3)"
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            color: "#FF6E00",
            mb: 4,
            fontFamily: "Doppio One",
            fontSize: "24px",
            lineHeight: "24px"
          }}
        >
          You may also like
        </Box>
        <Grid container spacing={2}>
          {similarReleases.slice(0, smallDisplay ? 4 : 6).map(release => (
            <Grid key={release.id} item lg={2} md={3} xs={6}>
              <Grid container direction={"row"}>
                <Grid item>
                  <ReleaseLink
                    release={release}
                    onClick={() => {
                      Analytics.reportSimilarReleaseClick(similarTo, release);
                    }}
                  >
                    <ArtWork
                      artworkImage={release.artworkImage}
                      album={release.album}
                    />
                  </ReleaseLink>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      mt: 2,
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "1.5px",
                      textTransform: "uppercase"
                    }}
                  >
                    {release.artist}
                  </Box>
                  <Box
                    sx={{
                      // mt: 1,
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.5px"
                    }}
                  >
                    {release.album}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider
          sx={{
            width: "70%",
            mx: "auto",
            color: "rgba(255, 255, 255, 0.3)"
          }}
        />
      </Grid>
    </Grid>
  );
};
