export type RootState = {
  router: any;
  app: AppState;
};

export interface AppState {
  loading: boolean;
  dataLoaded: boolean;
  selectedRelease?: Release;
  releases?: Release[];
  filter: Filter;
  filterOptions: FilterOptions;
}

export type Filter = {
  initialized?: boolean;
  genres?: string[];
  labels?: string[];
  offset?: number;
  includeSingles?: boolean;
  searchTerm?: string;
};

export type FilterOptions = {
  genres: string[];
  labels: string[];
  totalAlbums: number;
};

export enum ActionType {
  START_LOADADING = "START_LOADADING",
  STOP_LOADADING = "STOP_LOADADING",

  LOAD_FILTER_OPTIONS = "LOAD_FILTER_OPTIONS",
  LOAD_FILTER_OPTIONS_SUCCESS = "LOAD_FILTER_OPTIONS_SUCCESS",
  LOAD_RELEASES = "LOAD_RELEASES",
  LOAD_RELEASES_SUCCESS = "LOAD_RELEASES_SUCCESS",
  SELECT_RELEASE = "SELECT_RELEASE",
  SELECT_RELEASE_SUCCESS = "SELECT_RELEASE_SUCCESS",
  SELECT_RELEASE_CLEANUP = "SELECT_RELEASE_CLEANUP",

  SET_FILTER = "SET_FILTER",
  TOGGLE_FILTER_GENRE = "TOGGLE_FILTER_GENRE",
  TOGGLE_FILTER_LABEL = "TOGGLE_FILTER_LABEL",
  SET_FILTER_GENRES = "SET_FILTER_GENRES",
  SET_FILTER_LABELS = "SET_FILTER_LABELS",
  SET_FILTER_OFFSET = "SET_FILTER_OFFSET",
  SET_FILTER_SEARCH_TERM = "SET_FILTER_SEARCH_TERM",
  CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS",

  TRIGGER_ALBUM_FILL = "TRIGGER_ALBUM_FILL",
  TRIGGER_ALBUM_FILL_SUCCESS = "TRIGGER_ALBUM_FILL_SUCCESS",
  SUBMIT_NEW_ALBUM = "SUBMIT_NEW_ALBUM",
  SUBMIT_NEW_ALBUM_SUCCESS = "SUBMIT_NEW_ALBUM_SUCCESS"
}

export type StartLoadingAction = {
  type: typeof ActionType.START_LOADADING;
};

export type StopLoadingAction = {
  type: typeof ActionType.STOP_LOADADING;
};

export type LoadFilterOptionsAction = {
  type: typeof ActionType.LOAD_FILTER_OPTIONS;
};

export type LoadFilterOptionsSuccessAction = {
  type: typeof ActionType.LOAD_FILTER_OPTIONS_SUCCESS;
  filterOptions: FilterOptions;
};

export type LoadReleasesAction = {
  type: typeof ActionType.LOAD_RELEASES;
};

export type LoadReleasesSuccessAction = {
  type: typeof ActionType.LOAD_RELEASES_SUCCESS;
  releases: FetchReleasesResponse;
};

export type SelectReleaseAction = {
  type: typeof ActionType.SELECT_RELEASE;
  id: string;
};

export type SelectReleaseSuccessAction = {
  type: typeof ActionType.SELECT_RELEASE_SUCCESS;
  release: Release;
};

export type SelectReleaseCleanupAction = {
  type: typeof ActionType.SELECT_RELEASE_CLEANUP;
};

export type SetFilterAction = {
  type: typeof ActionType.SET_FILTER;
  filter: Filter;
};

export type ToggleFilterGenreAction = {
  type: typeof ActionType.TOGGLE_FILTER_GENRE;
  genre: string;
};

export type SetFilterGenresAction = {
  type: typeof ActionType.SET_FILTER_GENRES;
  genres: string[];
};

export type SetFilterLabelsAction = {
  type: typeof ActionType.SET_FILTER_LABELS;
  labels: string[];
};

export type ToggleFilterLabelAction = {
  type: typeof ActionType.TOGGLE_FILTER_LABEL;
  label: string;
};

export type SetFilterOffsetAction = {
  type: typeof ActionType.SET_FILTER_OFFSET;
  offset: number;
};

export type SetFilterSearchTermAction = {
  type: typeof ActionType.SET_FILTER_SEARCH_TERM;
  searchTerm: string;
};

export type ClearAllFiltersAction = {
  type: typeof ActionType.CLEAR_ALL_FILTERS;
};

export type AppActionTypes =
  | StartLoadingAction
  | StopLoadingAction
  | LoadFilterOptionsAction
  | LoadFilterOptionsSuccessAction
  | LoadReleasesAction
  | LoadReleasesSuccessAction
  | SelectReleaseAction
  | SelectReleaseSuccessAction
  | SelectReleaseCleanupAction
  | SetFilterAction
  | ToggleFilterGenreAction
  | ToggleFilterLabelAction
  | SetFilterGenresAction
  | SetFilterLabelsAction
  | SetFilterOffsetAction
  | SetFilterSearchTermAction
  | ClearAllFiltersAction;

export type FetchReleasesResponse = {
  count: number;
  albums: Release[];
};

export type ArtworkImage = {
  imageL: string;
  imageM: string;
  imageS: string;
};

export type Release = {
  id: string;
  album: string;
  artist: string;
  label: string;
  genre: string;
  single: boolean;
  artworkUrl: string;
  artworkImage: ArtworkImage;
  streamingServices: StreamingServices;
  buy: Buy;
  releaseDate: string;
  addedAt: string;
};

export type StreamingServices = {
  appleMusic?: string;
  spotify?: string;
  tidal?: string;
  amazonMusic?: string;
  deezer?: string;
  napster?: string;
  pandora?: string;
  youtube?: string;
  youtubeMusic?: string;
  googleplay?: string;
};

export type Buy = {
  title: string;
  link: string;
};
