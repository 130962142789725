import ReactGA from "react-ga4";
import { Location } from "history";
import { Release } from "../store/types";

export const GA_TRACKING_CODE_PROD = "G-YDVMR7N53E";
export const GA_TRACKING_CODE_DEV = "G-S9QFBJ6LWM";

export const Analytics = {
  initialize: () => {
    const trackingCode =
      process.env.NODE_ENV === "production"
        ? GA_TRACKING_CODE_PROD
        : GA_TRACKING_CODE_DEV;
    // const options = { debug: true, testMode: true };
    // console.log("initializing analytics with options ", options);
    // ReactGA.initialize(trackingCode, options);
    ReactGA.initialize(trackingCode);
    // console.log("Analytics initialized");
  },

  sendPageView: (location: Location): void => {
    try {
      ReactGA.set({ page: location.pathname });
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    } catch (e) {
      console.error(e);
    }
  },

  reportFilterGenre: (genre: string) => {
    try {
      ReactGA.event({
        action: "Genre",
        category: "Filter",
        label: genre
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportFilterLabel: (label: string) => {
    try {
      ReactGA.event({
        action: "Label",
        category: "Filter",
        label: label
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportHideSingles: () => {
    try {
      ReactGA.event({
        action: "HideSingles",
        category: "Filter"
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportFilterSearchTerm: (searchTerm: string) => {
    try {
      ReactGA.event({
        action: "Search",
        category: "Filter",
        label: searchTerm
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportFeedPageNavigation: (page: number) => {
    try {
      ReactGA.event({
        action: "Page",
        category: "Filter",
        value: page
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportStreamingService: (streamingServiceName: string, release: Release) => {
    // report album click
    try {
      ReactGA.event({
        action: streamingServiceName,
        category: "StreamingLink",
        label: release.id
      });
    } catch (e) {
      console.error(e);
    }
    // report lable use
    try {
      ReactGA.event({
        action: release.label,
        category: "LableUse",
        label: release.id
      });
    } catch (e) {
      console.error(e);
    }
  },

  reportShopClick: (shopName: string, releaseId: string) => {
    try {
      ReactGA.event({
        action: shopName,
        category: "ShopLink",
        label: releaseId
      });
    } catch (e) {
      console.error(e);
    }
  },
  reportSimilarReleaseClick(similarTo: Release, clickedRelease: Release) {
    try {
      ReactGA.event({
        action: "SimilarRelease",
        category: "Click",
        label: `${similarTo.id} -> ${clickedRelease.id}`
      });
    } catch (e) {
      console.error(e);
    }
  }
};
