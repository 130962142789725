import React, { FunctionComponent } from "react";
import icon from "./Tidal-icon.svg";
import iconExtended from "./tidal_logo_extended.jpg";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release };

const TidalButton: FunctionComponent<Props> = ({ release }) => {
  return (
    <StreamingButton
      link={release.streamingServices.tidal}
      release={release}
      icon={icon}
      extendedIcon={iconExtended}
      streamingServiceName={"tidal"}
    />
  );
};

export { TidalButton };
