import React, { FunctionComponent } from "react";
import iconBeatport from "./01_Beatport_Symbol_Green_Final.svg";
import iconBandcamp from "./bandcamp-button-circle-line-aqua-256.png";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release; noMargin: Boolean };

const ShopButton: FunctionComponent<Props> = ({ release, noMargin }) => {
  const isBandcamp = release.buy?.link?.includes("bandcamp");
  const isBeatport = release.buy?.link?.includes("beatport");

  const icon = isBeatport ? iconBeatport : iconBandcamp;

  // if not shop link
  if (!isBandcamp && !isBeatport) {
    return null;
  }

  return (
    <StreamingButton
      link={release.buy?.link}
      release={release}
      icon={icon}
      extendedIcon={icon}
      streamingServiceName={
        isBandcamp ? "bandcamp" : isBeatport ? "beatport" : "shop"
      }
      noMargin={!!noMargin}
    />
  );
};

export { ShopButton };
