import React, { FunctionComponent, useContext } from "react";
import { Box } from "@mui/material";
import { ExtendedButtonContext } from "./StreamingButtons";
import { Analytics } from "../../utils/analytics";
import { Release } from "../../store/types";

type Props = {
  link?: string;
  release: Release;
  icon: string;
  extendedIcon?: string;
  streamingServiceName: string;
  noMargin?: boolean;
};

const StreamingButtonContainer = ({
  children,
  extended,
  noMargin
}: {
  children: any;
  extended?: boolean;
  noMargin?: boolean;
}) => {
  return (
    <Box
      display={extended ? "block" : "inline-block"}
      mr={noMargin || extended ? 0 : 2}
    >
      {children}
    </Box>
  );
};
const StreamingButton: FunctionComponent<Props> = ({
  link,
  release,
  icon,
  extendedIcon,
  streamingServiceName,
  noMargin
}) => {
  const extended = useContext(ExtendedButtonContext);

  if (!link) {
    return null;
  } else {
    return (
      <StreamingButtonContainer extended={extended} noMargin={noMargin}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href={link}
          target={`psyfeed_${streamingServiceName}`}
          style={{
            display: "inline-block",
            overflow: "hidden",
            background: `url(${
              extended && extendedIcon ? extendedIcon : icon
            }) no-repeat center`,
            backgroundSize: "contain",
            width: extended && extendedIcon ? "170px" : "40px",
            height: extended && extendedIcon ? "70px" : "40px"
          }}
          onClick={() => {
            Analytics.reportStreamingService(streamingServiceName, release);
          }}
          aria-label={`${streamingServiceName} link`}
        />
      </StreamingButtonContainer>
    );
  }
};

export { StreamingButton };
