import React, { FunctionComponent, useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { loadGenresAction } from "../store/actions";

type StateProps = {};
type DispatchProps = {
  loadGenres: () => void;
};

type Props = StateProps & DispatchProps;

const StartupComponent: FunctionComponent<Props> = ({
  children,
  loadGenres
}) => {
  useEffect(() => {
    loadGenres();
    // eslint-disable-next-line
  }, []);
  return <>{children}</>;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  loadGenres: () => {
    dispatch(loadGenresAction());
  }
});

const Startup = connect(null, mapDispatchToProps)(StartupComponent);

export { Startup, StartupComponent };
