import { createSelector } from "reselect";
import { AppState, RootState } from "./types";
import { initialState } from "./reducer";

const selectAppDomain = (state: RootState): AppState =>
  state.app || initialState;

export const makeSelectLoading = () =>
  createSelector(selectAppDomain, app => app.loading);

export const makeSelectReleases = () =>
  createSelector(selectAppDomain, app => app.releases);

export const makeSelectSelectedRelease = () =>
  createSelector(selectAppDomain, app => app.selectedRelease);

export const makeSelectFilter = () =>
  createSelector(selectAppDomain, app => app.filter);

export const makeSelectFilterOptions = () =>
  createSelector(selectAppDomain, app => app.filterOptions);
