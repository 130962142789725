import { styled } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Release } from "../../store/types";
import React, { FunctionComponent } from "react";
import { AppRoutes } from "../../utils/routes";

const StyledRouterLink = styled(RouterLink)``;

type ReleaseLinkProps = { release: Release; onClick?: () => void };
export const ReleaseLink: FunctionComponent<ReleaseLinkProps> = ({
  release,
  onClick,
  children
}) => {
  return (
    <StyledRouterLink
      sx={{
        overflow: "hidden",
        textDecoration: "none",
        color: "text.primary",
        cursor: "pointer"
      }}
      to={`${AppRoutes.RELEASE_DETAILS_ROUTE}/${release.id}`}
      onClick={onClick}
    >
      {children}
    </StyledRouterLink>
  );
};
