import React, { FunctionComponent } from "react";
import icon from "./AppleMusic-icon.png";
import extendedIcon from "./US-UK_Apple_Music_lockup_RGB_wht.png";
import { StreamingButton } from "../StreamingButton";
import { Release } from "../../../store/types";

type Props = { release: Release };

const AppleMusicButton: FunctionComponent<Props> = ({ release }) => {
  return (
    <StreamingButton
      link={release.streamingServices.appleMusic}
      release={release}
      icon={icon}
      extendedIcon={extendedIcon}
      streamingServiceName={"appleMusic"}
    />
  );
};

export { AppleMusicButton };
